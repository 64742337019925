.tab-user-card {
  .nav-pills {
    background: #ecf0f5;
    padding: 20px;
    border-radius: 5px;
  }
}

.dataTables_wrapper .dataTables_paginate .paginate_button.active {
  background: $primary-color;
  border-color: $primary-color;
}

.sw-theme-default .Loki .sw-container {
  min-height: 0px;
}

.sw-theme-default .Loki > ul.step-anchor > li {
  margin: 0px 40px;
}

.slick-slider .slick-next {
  right: -15px;
  z-index: 1;
  transform: scale(2.5);
}

.slick-slider .slick-next {
  right: -15px;
  z-index: 1;
  transform: scale(2.5);
}

.slick-slider .slick-prev {
  left: -15px;
  z-index: 1;
  transform: scale(2.5);
}

// .calculator-retailer-block .card-img-top {
//   transform: scale(0.7);
// }

.retailer-discount-block {
  min-height: 120px;
}


.retailer-discount-block h2{
  font-size: 22px;
}

.retailer-electricity-rate-block {
  max-height: 200px;
  overflow-y: auto;
  min-height: 140px;
}

.retailer-features-block {
  max-height: 150px;
  min-height: 150px;
  overflow-y: auto;
}

.retailer-pitch-button-block {
  //min-height: 150px;
  text-align: center;
}

.retailer-description-block
{
  max-height: 190px;
  min-height: 190px;
  overflow-y: auto;

  li{
    font-size:16px;
  }
}

.product-description-block {
  max-height: 200px;
  min-height: 200px;
  overflow-y: auto;
  text-align: justify;
}

.product-extra-details-button-block {
  min-height: 100px;
  text-align: center;
}

.product-name-block {
  max-height: 40px;
  min-height: 40px;
  overflow-y: auto;
}


.retailer-dmovdo-script-block{
  // max-height: 160px;  
  overflow-y: auto;
  text-align: center;
}

.retailer-dmovdo-month-annum-cost-block {
  min-height: 80px;
}

.retailer-dmovdo-percentage-difference-block {
  min-height: 95px;
  text-align: center;
}

.retailer-pcs-block {
  min-height: 150px;
}

.retailer-electricity-rate-block .card-body {
  padding: 20px 20px 0px 20px;
}

.retailer-electricity-rate-block .card-body .mr-1 {
  margin-left: 10px;
}

.calculator-retailer-block .card-header {
  border-top: 1px solid #e2e5e8;
}

.retailerScriptInputs {
  max-width: 350px;
  display: inline;
  min-height: 40px;
}

.retailerScriptInputs input {
  max-width: 350px;
  display: inline;
  margin-bottom: 20px;
}

.form-control {
  border: 1px solid #4680ff;
  border-radius: 5px;
  padding-left: 10px !important;
}

.script-generator .form-control {
  min-height: 50px;
}

.form-group select {
  height: 40px;
}

.comparison-module .widget-statstic-card span {
  color: #6c747d;
  border-radius: 0px;
}

.tab-content .lead-details .form-control {
  margin: 10px;
}

.widget-statstic-card .rdtPicker span {
  color: #4680ff;
}

.rdtPicker thead tr:first-child th:hover {
  background: #eaeef3;
}

.rdtCounter .rdtBtn:hover {
  background: none;
}

.user-profile-list table tbody tr:hover td a {
  color: #ffffff !important;
}

.user-profile-list table tbody tr:hover td .badge {
  border: 1px solid #ffffff;
}

.user-profile-list table tbody .row-disabled:hover {
  box-shadow: 0 0 6px 0 #9ccd64 !important;
}

.user-profile-list table tbody .row-disabled:hover td {
  background: #9ccd64 !important;
}

.ma {
  margin: auto;
}

.sales-dashboard .slick-slide img {
  display: inline;
}

.text-purple {
  color: #7400dc !important;
}

.scriptBlockDisplay .row .col-md-2 {
  margin: auto 0px;
}

.scriptBlockDisplay .row .col-md-6 {
  margin: auto 0px;
}

.custom-icon-config {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.select__indicator-separator {
  padding: 0px !important;
}

.was-validated .invalid-feedback {
  display: block;
}

.jumbotron {
  padding: 2rem 1rem !important;
}

.single-plan .retailer-logo{
  max-width: 200px;
}

.other-fee-block{
  padding: 10px 20px 5px !important;
}